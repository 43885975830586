<template>
  <form class="add main-block col-lg-6 col-md-12" @submit.prevent="managerAdd">
    <div class="add__box main-block__head">
      <h1 class="add__box-title main-block__title">Информация</h1>
      <button type="submit" class="main-block__btn default-btn">Сохранить</button>
    </div>
    <ul class="add__items">
      <li class="add__item flex">
        <p class="add__key">Фамилия *</p>
        <input v-model="surname" type="text" class="add__value" placeholder="Фамилия" />
      </li>
      <li class="add__item flex">
        <p class="add__key">Имя *</p>
        <input v-model="name" type="text" class="add__value" placeholder="Имя" />
      </li>
      <li class="add__item flex">
        <p class="add__key">Email *</p>
        <input
          v-model="email"
          type="email"
          class="add__value add__value_aligin"
          placeholder="Email"
        />
      </li>
      <li class="add__item flex">
        <p class="add__key">Позиция *</p>
        <b-form-select
          v-if="positionsProducts"
          v-model="positionId"
          class="add__value"
          :options="positionsProducts.data.data"
          text-field="title"
          value-field="id"
        />
      </li>
      <li class="add__item flex">
        <p class="add__key">Пароль *</p>
        <input
          v-model="password"
          type="password"
          class="add__value add__value_aligin"
          placeholder="Пароль"
        />
      </li>
    </ul>
  </form>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      managerData: null,
      selected: null,
      name: "",
      surname: "",
      email: "",
      password: "",
      positionId: "",
    };
  },
  computed: {
    ...mapState(["citiesProducts", "positionsProducts", "employersList"]),
    list() {
      if (this.citiesProducts) {
        return [
          {
            mark_id: null,
            name: "Выберите",
            disabled: true,
          },
          ...this.citiesProducts,
        ];
      }
      return [
        {
          mark_id: null,
          name: "Выберите",
          disabled: true,
        },
      ];
    },
  },
  watch: {},
  async created() {
    await this.$store.dispatch("getEmployersList");
    if (this.$route.query.id) {
      const employee = this.employersList.find((e) => +e.id === +this.$route.query.id);
      if (employee) {
        this.name = employee.name;
        this.surname = employee.surname;
        this.email = employee.login;
        this.positionId = employee.position?.id;
      }
    }
    await this.$store.dispatch("getPositionsProducts");
  },
  mounted() {
    if (this.employersList && this.$route.query.id) {
      this.managerData = this.employersList.find((e) => e.id === +this.$route.query.id);
      this.name = this.managerData.name;
    }
  },
  methods: {
    async managerAdd() {
      const formData = new FormData();
      if (this.surname) formData.append("surname", this.surname);
      if (this.name) formData.append("name", this.name);
      if (this.email) formData.append("email", this.email);
      if (this.password) formData.append("password", this.password);
      if (this.positionId) formData.append("position_id", this.positionId);
      if (this.$route.query.id) formData.append("employee_id", this.$route.query.id);
      const url = this.$route.query.id ? "/web/edit-manager" : "/web/add-employee";
      await this.$api
        .post(url, formData)
        .then(() => {
          this.$toast.success("Успешно!");
          this.$store.dispatch("getEmployersList");
          this.$router.push("/database/managers");
        })
        .catch((e) => {
          this.$toast.error(e.response.data);
        });
    },
  },
};
</script>

<style scoped>
.add__items {
  font-size: 12px;
  line-height: 14px;
  color: #707070;
  padding: 3.5px 0 0 0;
}

.add__item {
  padding-top: 11.5px;
  height: 27.5px;
  position: relative;
}

.add__item:not(:last-child) {
  padding-bottom: 11.5px;
  height: 39px;
}

.main__select-icon {
  right: 8px;
  top: 53%;
  width: 7px;
}

.add__key {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  color: #000000;
}

.add__value {
  width: 48%;
  padding-bottom: 3px;
  padding-left: 3px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #acacac;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  padding-right: 10px;
}

.transport-add__value {
  width: 48%;
  padding-bottom: 3px;
  padding-left: 3px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #acacac;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  padding-right: 10px;
}
</style>
